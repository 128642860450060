const constants : any= {
    // math
    ADDITION: '+',
    SUBSTRACT: '-',
    PERCENTAGE_ADD: '+%',
    PERCENTAGE_SUB: '-%',

    // roles
    SUPERADMIN: 1,
    STAFF: 2,
    TEACHER: 3,
    STUDENT: 4,
    STUDENT_PARENT: 5,

    // bill status
    BILL_PENDING: 110,
    BILL_UNPAID: 120,
    BILL_PAID: 130,
    BILL_OVERPAID: 140,

    // order status
    ORDER_BEGIN: 310,
    ORDER_PENDING: 320,
    ORDER_SUCCESS: 330,
    ORDER_SUCCESS_MANUAL: 331,
    ORDER_CANCELED: 340,
    ORDER_EXPIRED: 341,

    // installment status
    INSTALLMENT_UNPAID: 0,
    INSTALLMENT_PAID: 1,
    // log status
    10: 'buat',
    11: 'generate',
    20: 'ubah',
    21: 'publish',
    22: 'unpublish',
    30: 'hapus'

}
export default constants
