import axios from 'axios'
import { orderFactory } from './order'

class UserBillFactory {

  async userBill(param:any, id: any) {
    try {
      let url = `/users/${id}/bills`
      var response = await axios.get(url, {
        params: {
          school_year_id: param.school_year_id
        }
      })
      return response.data
    } catch (error) {
      console.error(error)
      return false
    }
  }

  async billUserDetail(bill_id:any, bill_user_id: any) {
    try {
      let url = `/bills/${bill_id}/users/${bill_user_id}`
      var response = await axios.get(url)
      return response.data
    } catch (error) {
      console.error(error)
      return false
    }
  }

  async updateBillUser (bill_id:any, bill_user_id: any, data: any) {
    try {
      let url = `/bills/${bill_id}/users/${bill_user_id}`
      var response = await axios.put(url, data)
      return response.data
    } catch (error) {
      console.error(error)
      return error
    }
  }

  async userBillSummary(param:any) {
    try {
      let url = `/bill-users`
      var response = await axios.get(url, {
        params: {
          school_year_id: param.school_year_id,
          class_id: param.class_id
        }
      })
      return response.data
    } catch (error) {
      console.error(error)
      return false
    }
  }

  async userBillParam(param:any) {
    try {
      let url = `/bill-users/list`
      var response = await axios.get(url, {
        params: {
          school_year_id: param.school_year_id,
          user_id: param.user_id,
          status: param.status,
          month: param.month,
          order_by: param.order_by,
          order_at: param.order_at,
          keyword: param.keyword,
          spp_bill_id: param.spp_bill_id,
          limit: param.limit,
          offset: param.offset,
          class_id: param.class_id
        }
      })
      return response.data
    } catch (error) {
      console.error(error)
      return false
    }
  }

  async userBillPublish(param:any) {
    try {
      let url = `/bill-users/publishing`
      var response = await axios.put(url,param)
      return response.data
    } catch (error) {
      console.error(error)
      return error
    }
  }

  async exportBill (data: any) {
    try {
      let url = '/bill-users/export'
      var response = await axios.get(url, {
          responseType: 'arraybuffer',
          params: {
            status: data.status,
            school_year_id: data.school_year_id,
            month: data.month,
            keyword: data.keyword,
            order_by: data.order_by,
            order_at: data.order_at,
            spp_bill_id: data.spp_bill_id,
            user_id: data.user_id,
            class_id: data.class_id
        }
      })
      let blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      let link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'Ekspor_Tagihan.xlsx'
      link.click()
    } catch (error) {
      console.error(error)
      return false
    }
  }

  async templateDetailImport () {
    try {
      let url = '/bill-users/detail/template'
      var response = await axios.get(url, {
          responseType: 'arraybuffer',
          params: { }
      })
      let blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      let link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'Template_Impor_Rincian_Tagihan.xlsx'
      link.click()
    } catch (error) {
      console.error(error)
      return false
    }
  }

  async importDetail (data: any) {
    try {
      let url = '/bill-users/detail/import'
      var response = await axios.post(url, data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      return response.data
    } catch (error) {
      console.error(error)
      return false
    }
  }

  async applyImportDetail (data: any) {
    try {
      let url = `/bill-users/detail/import`
      var response = await axios.put(url, { update: data })
      return response.data
    } catch (error) {
      console.error(error)
      return error
    }
  }

}


export let userBillFactory = new UserBillFactory()
